<div>
  <h3 style="font-size: 26px; font-weight: 500">Notifications</h3>

  <div class="main">
    <div class="d-block">
      <div class="d-flex align-items-start">
        <div class="" style="width: 35%; border-right: 1px solid #e4e7ec">
          <div
            class="py-2 px-4 d-flex w-100"
            style="border-bottom: 1px solid #e4e7ec"
          >
            <span class="search-section">
              <svg
                class="search-icon"
                width="20"
                height="21"
                viewBox="0 0 20 21"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M17.5 18L13.875 14.375M15.8333 9.66667C15.8333 13.3486 12.8486 16.3333 9.16667 16.3333C5.48477 16.3333 2.5 13.3486 2.5 9.66667C2.5 5.98477 5.48477 3 9.16667 3C12.8486 3 15.8333 5.98477 15.8333 9.66667Z"
                  stroke="#101828"
                  stroke-width="1.66667"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
              <input
                class="date-select w-100"
                id="search_input"
                type="text"
                [(ngModel)]="keyword"
                (keyup.enter)="search()"
                placeholder="Search"
              />
            </span>
            &nbsp;
            <span
              class="ml-2 pointer"
              (click)="clear()"
              *ngIf="isSearch"
              (keydown.press)="clear()"
            >
              <svg
                width="18"
                height="18"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M6 6.00002L18.7742 18.7742M6 18.7742L18.7742 6"
                  stroke="#141C2B"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </span>
          </div>

          <div class="mt-1 px-4 notifications-container">
            <div
              class="d-flex align-items-center justify-content-between py-1 pr-1"
            >
              <div class="d-flex align-items-center">
                <span>Unread </span
                ><span class="unread ml-2">{{ notificationCount }}</span>
              </div>
              <div class="">
                <div
                  class="d-flex justify-content-end"
                  *ngIf="tobeReadNotifications.length"
                >
                  <div class="custom-control custom-switch d-flex">
                    <input
                      type="checkbox"
                      class="custom-control-input"
                      (change)="readNotifcations($event)"
                      id="customSwitch1"
                    />
                    <label class="custom-control-label" for="customSwitch1"
                      >Mark selected as read</label
                    >
                  </div>
                </div>

                <div
                  class="d-flex justify-content-end"
                  *ngIf="tobeReadNotifications.length === 0"
                >
                  <div class="custom-control custom-switch d-flex">
                    <input
                      type="checkbox"
                      class="custom-control-input"
                      (change)="onMarkAll($event)"
                      id="customSwitch1"
                    />
                    <label class="custom-control-label" for="customSwitch1"
                      >Mark all as read</label
                    >
                  </div>
                </div>
              </div>
            </div>
            <div class="notifications-controller">
              <ng-container *ngIf="allNotifications.length > 0">
                <div *ngFor="let item of allNotifications">
                  <div
                    (click)="view(item)"
                    (keydown.press)="view(item)"
                    class="my-1 notification"
                    style="border-radius: 5px"
                    [ngClass]="{
                      'active-notification': selectedItem === item,
                      'unread-notification': item.notificationStatus === 1
                    }"
                  >
                    <div class="p-3 pointer">
                      <p class="txt mb-2">
                        {{ getRelativeDate(item?.dateCreated) }}
                      </p>
                      <div class="mb-2 d-flex align-items-center">
                        <input
                          type="checkbox"
                          (click)="handleCheckboxClick($event)"
                          (keyup)="handleKeyUp($event)"
                          (change)="onMark($event, item.id)"
                        />
                        <p class="pl-2 mb-0 tt" style="font-weight: 600">
                          {{ item?.title || "N/A" }}
                        </p>
                      </div>
                      <div>
                        <p class="pl-4 txt2">
                          {{
                            item?.description && item?.description.length > 50
                              ? item?.description.slice(0, 50) + "..."
                              : item?.description
                          }}
                        </p>
                        <p class="mb-0 text-right">
                          {{ item?.dateCreated | date }}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </ng-container>
              <ng-container *ngIf="allNotifications.length === 0">
                <div class="m-auto py-5">
                  <div class="d-flex pt-5 justify-content-center">
                    <img
                      src="../../../../assets/images/Illustration.svg"
                      class="unavailable mb-4"
                      alt=""
                    />
                  </div>
                  <h5 class="text-center pb-5">No notifications available</h5>
                </div>
              </ng-container>
            </div>
          </div>
        </div>
        <div style="width: 65%">
          <div class="pl-5 pr-3" style="margin-top: 60px">
            <div *ngIf="selectedItem === null" class="d-flex message-section">
              <img
                class="m-auto"
                src="/assets/images/no-note.svg"
                alt="no-note"
              />
            </div>

            <div *ngIf="selectedItem !== null" class="message-section">
              <div class="title-section d-flex justify-content-between p-3">
                <h4 class="mb-0" style="font-weight: 600">
                  {{ selectedItem?.title }}
                </h4>
                <span>
                  <input type="checkbox" (change)="markAsRead($event)" />
                  &nbsp;
                  <span>Mark as read</span>
                </span>
              </div>

              <div class="p-5">
                <div class="mb-2 d-flex align-items-center">
                  <img
                    src="/assets/images/app-logo.png"
                    alt="app-logo"
                    width="40"
                    height="40"
                  />
                  &nbsp; &nbsp;
                  <h3 class="mb-0" style="font-weight: 600">FCMB</h3>
                </div>

                <div class="mt-5">
                  <h3 class="mb-0" style="font-weight: 600">
                    {{ selectedItem?.title }}
                  </h3>
                  <p class="txt2 mt-2">
                    {{ selectedItem?.description }}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="">
      <mat-paginator
        color="primary"
        [length]="pageEvent.length"
        [pageSize]="pageEvent.pageSize"
        [showFirstLastButtons]="true"
        (page)="onPageChange($event)"
      >
      </mat-paginator>
    </div>
  </div>
</div>
