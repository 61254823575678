import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { MerchantGuard } from './authentication/auth-guard/merchant-guard/merchant.guard';
import { AdminGuard } from './authentication/auth-guard/admin-guard/admin.guard';
import { BranchGuard } from './authentication/auth-guard/branch-guard/branch.guard';
import { OperationGuard } from './authentication/auth-guard/operation-guard/operation.guard';
import { ControlUnitGuard } from './authentication/auth-guard/control-unit-guard/control-unit.guard';

const routes: Routes = [
  {
    path: '', redirectTo: '/auth', pathMatch: 'full'
  },
  {
    path: 'auth',
    loadChildren: () =>
      import('./authentication/authentication.module').then(
        m => m.AuthenticationModule
      )
  },
  {
    path: 'merchant',
    loadChildren: () =>
      import('./merchant/merchant.module').then(m => m.MerchantModule),
    canActivate: [MerchantGuard]
  },
  {
    path: 'admin',
    loadChildren: () =>
      import('./admin/admin.module').then(m => m.AdminModule),
    canActivate: [AdminGuard]
  },
  {
    path: 'branch',
    loadChildren: () =>
      import('./branch/branch.module').then(m => m.BranchModule),
    canActivate: [BranchGuard]
  },
  {
    path: 'operations',
    loadChildren: () =>
      import('./operations/operations.module').then(m => m.OperationsModule),
    canActivate: [OperationGuard]
  },
  {
    path: 'control',
    loadChildren: () =>
      import('./control-unit/control-unit.module').then(m => m.ControlUnitModule),
    canActivate: [ControlUnitGuard]
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      useHash: true,
      preloadingStrategy: PreloadAllModules,
      scrollPositionRestoration: 'enabled'
    })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
