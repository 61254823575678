import { CredentialsService } from './../../../app/authentication/credentials/credentials.service';
import { Injectable } from '@angular/core';
import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest
} from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  constructor(private credentialsService: CredentialsService) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (this.credentialsService.credentials !== null) {
      const { accessToken } = this.credentialsService.credentials;
      if (accessToken !== null) {
        request = request.clone({
          setHeaders: {
            Authorization: `Bearer ${accessToken}`,
            Accept: 'application/json'
          }
        });
      }
    }

    return next.handle(request);
  }
}
