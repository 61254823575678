<div class="d-none d-lg-block">
  <div class="d-flex align-items-center">
    <img
      src="{{ credentials()?.Image }}"
      class="profile-image"
      alt="credentials"
      *ngIf="credentials()?.Image"
      alt="image"
    />
    <div *ngIf="!credentials()?.Image" class="profile-image"></div>
    &nbsp; &nbsp;
    <span class="user-txt">
      <strong class="f-500">{{ credentials()?.DisplayName || "N/A" }}</strong>
      <br />
      <span>SOL ID: {{ credentials()?.Sol || "N/A" }}</span>
    </span>
  </div>
  <div class="mt-3 user-txt">
    <p class="mb-0">
      Last Login: <br />
      {{ credentials()?.LastLogin }}
    </p>
  </div>
</div>

<div class="d-block d-lg-none">
  <div class="d-flex align-items-center">
    <img
      src="{{ credentials()?.Image }}"
      class="profile-image"
      *ngIf="credentials()?.Image"
      alt="credential profile"
    />
    <div *ngIf="!credentials()?.Image" class="profile-image"></div>
    &nbsp; &nbsp;
    <span class="user-txt-black">
      <strong>{{ credentials()?.DisplayName || "N/A" }}</strong> <br />
      <span>SOL ID: {{ credentials()?.Sol || "N/A" }}</span>
    </span>
  </div>
</div>
