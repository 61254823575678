import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoaderComponent } from './loader/loader.component';
import { AlertComponent } from './alert/alert.component';
import { ServicesModule } from 'src/services/services.module';
import { RouterModule } from '@angular/router';
import { DrawerComponent } from './drawer/drawer.component';
import { SidebarComponent } from './sidebar/sidebar.component';
import { UserDetailsComponent } from './user-details/user-details.component';
import { UserNotificationComponent } from './user-notification/user-notification.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { EmptyStateComponent } from './empty-state/empty-state.component';
import { MatPaginatorModule } from '@angular/material/paginator';
import { CustomDateModalComponent } from './custom-date-modal/custom-date-modal.component';

@NgModule({
  declarations: [
    AlertComponent,
    LoaderComponent,
    DrawerComponent,
    UserDetailsComponent,
    SidebarComponent,
    UserNotificationComponent,
    EmptyStateComponent,
    CustomDateModalComponent,
  ],
  imports: [
    CommonModule,
    MatPaginatorModule,
    ServicesModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    MatPaginatorModule,
    MatIconModule,
  ],
  exports: [
    AlertComponent,
    LoaderComponent,
    DrawerComponent,
    SidebarComponent,
    UserDetailsComponent,
    UserNotificationComponent,
    EmptyStateComponent,
    CustomDateModalComponent,
  ],
})
export class SharedModule {}
