import { Component, OnInit, OnDestroy } from '@angular/core';
import { SignalRService } from 'src/services/data/signal/signal.service';
import { CredentialsService } from './authentication/credentials/credentials.service';
import { NotificationsService } from 'src/services/classes/notifications/notifications.service';
import { BnNgIdleService } from 'bn-ng-idle';
import { AuthService } from 'src/services/data/auth/auth.service';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: [],
})
export class AppComponent implements OnInit, OnDestroy {
  title = 'fcmb-nou-fe';
  private idleSubscription!: Subscription;

  constructor(
    private signalService: SignalRService,
    private credentialService: CredentialsService,
    private notify: NotificationsService,
    private bnIdle: BnNgIdleService,
    private authService: AuthService,
    private router: Router
  ) {}

  ngOnInit() {
    this.idleSubscription = this.bnIdle.startWatching(300).subscribe((res) => {
      if (res) {
        this.callLogout();
      }
    });

    this.signalService.startConnection(
      this.credentialService.credentials?.accessToken
    );

    this.signalService
      .receiveClaimProcessHistoryNotification()
      .subscribe((res) => {
        this.notify.publishMessages(res, 'success', 1);
      });
  }

  callLogout() {
    this.authService.logout({}).subscribe((res) => {
      if (!res.hasErrors) {
        this.handleLogoutFeedBack(res?.payload);
      }
    });
  }

  handleLogoutFeedBack(payload: string) {
    this.credentialService.setCredentials();
    this.router.navigate(['/auth']);
    this.notify.publishMessages(payload, 'success', 1);
    this.bnIdle.stopTimer();
    if (this.idleSubscription) {
      this.idleSubscription.unsubscribe();
    }
  }

  ngOnDestroy() {
    if (this.idleSubscription) {
      this.idleSubscription.unsubscribe();
    }
    this.bnIdle.stopTimer();
  }
}
