import { Injectable } from '@angular/core';
import { Observable, take } from 'rxjs';
import { BaseService } from 'src/services/data/base/base.service';

const routes = {
  allNotifications: 'Notification/GetAllNotificationsForAUser',
  notification: 'Notification/GetANotificationForAUser',
};

export interface IMessageForSingleUser {
  payload: Payload[];
  totalCount: number;
  responseCode: any;
  code: string;
  description: string;
  errors: any[];
  hasErrors: boolean;
}

export interface Payload {
  id: string;
  senderId: string;
  senderKnownAs: string;
  attachment: any;
  attachmentName: string;
  recipientId: string;
  recipientKnownAs: string;
  content: string;
  isRead: boolean;
  dateRead: any;
  dateSent: string;
  title: string;
}

export interface IFilterClaimsPayload {
  BranchApprovalStatus?: any;
  startDate?: any;
  endDate?: any;
  product?: any;
  claimType?: any;
  solId?: any;
}
export interface QueryForSingleMessage {
  messageContainer?: any;
  senderId?: any;
  keyword?: any;
  filter?: any;
  pageIndex?: any;
  pageSize?: any;
}

export interface IgetClaimsStatusCount {
  claimType?: any;
  solId?: any;
  fromDate?: any;
  toDate?: any;
  timeRange?: any;
}

export interface IGetClaimsCategory {
  payload: Payload;
  totalCount: number;
  responseCode: any;
  code: any;
  description: string;
  errors: any[];
  hasErrors: boolean;
}

export interface Payload {
  newClaim: number;
  inProgress: number;
  resolved: number;
  declined: number;
}

export interface IGetAllUserList {
  payload: Payload[];
  totalCount: number;
  responseCode: any;
  code: any;
  description: any;
  errors: any[];
  hasErrors: boolean;
}

export interface Payload {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
}

export interface IGetClaimsReport {
  solID: string;
  authCode: any;
  originalSerialNumber: any;
  claimsId: string;
  fileName: string;
  dateUploaded: string;
  logCode: string;
  tat: number;
  isExpired: boolean;
  terminalId: string;
  transactionID: any;
  stanID: string;
  transationDate: string;
  product: string;
  cardNumber: string;
  amount: number;
  status: string;
}

@Injectable({
  providedIn: 'root',
})
export class MessageService extends BaseService<any> {
  fetchAllNotifications(
    pageIndex: number,
    pageSize: number,
    UserId: any,
    keyword?: string,
    filter?: string
  ): Observable<any> {
    return this.sendGet(
      this.baseUrl(
        `${routes.allNotifications}?UserId=${UserId}&PageIndex=${pageIndex}&PageSize=${pageSize}&Keyword=${keyword}&Filter=${filter}`
      )
    );
  }

  fetchNotification(id: any) {
    return this.sendGet(this.baseUrl(`${routes.notification}?Id=${id}`));
  }

  GetMessageThread(SupportId: string): Observable<any> {
    let url = this.baseUrl(
      `Message/GetMessagesThreadForUserAsAList?SupportId=${SupportId}`
    );

    return this.sendGet(url);
  }

  MarkMessagesAsReadOrUnread(payload: any): Observable<any> {
    let url = this.baseUrl(`Message/MarkMessagesAsReadOrUnread`);
    return this.sendPut(url, payload);
  }

  getClaimsStatusCount(queryParameter: IgetClaimsStatusCount): Observable<any> {
    let url = this.baseUrl(
      `Dashboard/GetClaimsStatusCount?ClaimType=${queryParameter.claimType}&SolId=${queryParameter.solId}&FromDate=${queryParameter.fromDate}&ToDate=${queryParameter.toDate}&TimeRange=${queryParameter.timeRange}`
    );
    return this.sendGet(url);
  }

  getAllUsers(): Observable<any> {
    let branchEnum = 6;
    let url = this.baseUrl(`User/GetAllUsers?UserType=${branchEnum}`);
    return this.sendGet(url).pipe(take(1));
  }

  getChatStaffUsers(): Observable<any> {
    let url = this.baseUrl(`User/GetAllUsers?IsStaffUsers=true`);
    return this.sendGet(url).pipe(take(1));
  }

  readNofication(payload: any): Observable<any> {
    return this.sendPut(
      this.baseUrl('Notification/MarkNotificationAsReadUnread'),
      payload
    );
  }

  unreadNoficationCount(userId: any) {
    return this.sendGet(
      this.baseUrl(`Notification/NotificationStatusCount/${userId}`)
    );
  }

  createMessage(payload: any): Observable<any> {
    return this.httpClient.post(this.baseUrl(`Message/CreateMessage`), payload);
  }

  messageDefault(payload: any): Observable<any> {
    return this.httpClient.post(
      this.baseUrl(`Message/CreateMessageDefault`),
      payload
    );
  }

  getTatAnalyticCount(
    solId: string,
    startDate: any,
    endDate: any,
    range: any,
    tat: any,
    claimType: any
  ) {
    let url = this.baseUrl(
      `Dashboard/GetTATAnalyticsForBranch?TimeBoundSearchVm.TimeRange=${range}&SolId=${solId}`
    );

    if (claimType) {
      url += `&ClaimType=${claimType}`;
    }
    if (tat) {
      url += `&TatDashboard=${tat}`;
    }

    if (startDate) {
      url += `&TimeBoundSearchVm.FromDate=${startDate}`;
    }
    if (endDate) {
      url += `&TimeBoundSearchVm.ToDate=${endDate}`;
    }
    return this.sendGet(url);
  }

  getClaimsReport(reportType: any) {
    let url = this.baseUrl(
      `Reports/paginated-branch-report?ReportType=${reportType}`
    );
    return this.sendGet(url);
  }

  getBranchClaims(queryParams: any) {
    let url = this.baseUrl(`Branch/Claims?SolId=${queryParams.solId}`);

    if (queryParams.BranchApprovalStatus) {
      url += `&BranchApprovalStatus=${queryParams.BranchApprovalStatus}`;
    }
    if (queryParams.claimType) {
      url += `&ClaimType=${queryParams.claimType}`;
    }
    if (queryParams.startDate) {
      url += `&TimeBoundSearchVm.FromDate=${queryParams.startDate}`;
    }
    if (queryParams.endDate) {
      url += `&TimeBoundSearchVm.ToDate=${queryParams.endDate}`;
    }
    if (queryParams.product) {
      url += `&Product=${queryParams.product}`;
    }

    return this.sendGet(url);
  }

  getProcessReportForAClaim(ClaimBatchId: any) {
    let url = this.baseUrl(
      `Branch/Process-Reports-From-A-Claim?ClaimId=${ClaimBatchId}`
    );
    return this.sendGet(url);
  }

  performActionClaim(payload: any) {
    let url = this.baseUrl(`Branch/Action-On-A-Claim`);
    return this.httpClient.post(url, payload);
  }

  testNotification() {
    return this.httpClient;
  }

  getRelativeDate(date: any): string {
    const formattedDate = new Date(date);
    const now = new Date();
    const diffInMilliseconds = now.getTime() - formattedDate.getTime();
    const diffInDays = Math.floor(diffInMilliseconds / (1000 * 60 * 60 * 24));

    if (diffInDays === 0) {
      return 'today';
    } else if (diffInDays === 1) {
      return '1 day ago';
    } else {
      return `${diffInDays} days ago`;
    }
  }

  getRecentClaim(
    solId: any,
    startDate: any,
    endDate: any,
    range: any,
    tat: any,
    claimType: any,
    claimStatus: any
  ) {
    let url = this.baseUrl(
      `Dashboard/RecentClaimBatchesForBranch?TimeBoundSearchVm.TimeRange=${range}&SolId=${solId}`
    );
    if (claimType) {
      url += `&ClaimType=${claimType}`;
    }
    if (tat) {
      url += `&&TatDashboard=${tat}`;
    }
    if (claimStatus) {
      url += `&ClaimStatus=${claimStatus}`;
    }

    if (startDate) {
      url += `&TimeBoundSearchVm.FromDate=${startDate}`;
    }
    if (endDate) {
      url += `&TimeBoundSearchVm.ToDate=${endDate}`;
    }
    return this.sendGet(url);
  }
}
