<div class="main p-3">
  <div class="py-3 d-flex align-items-center logo-section">
    <img src="assets/images/app-logo.png" height="40" alt="logo" /> &nbsp;
    &nbsp;
    <span class="app-name">ATM NOU</span>
  </div>

  <div class="my-3 search-section">
    <img src="/assets/icons/search-icon.svg" alt="search-icon" />
    <input
      (keyup)="searchMenu($event)"
      type="text"
      class="search-input"
      placeholder="Search"
    />
  </div>

  <ul class="list-items">
    <li style="list-style: none" *ngFor="let item of sideBarMenuItems">
      <a
        routerLinkActive="active"
        routerLink="{{ item.url }}"
        class="sidenav-menu-item d-flex align-items-center justify-content-between"
        (click)="showChildren(item)"
      >
        <span class="d-flex align-items-center">
          <img
            src="{{ item.activeLink }}"
            alt="link-img"
            [ngClass]="currentUrl?.includes(item?.url) ? 'd-block' : 'd-none'"
          />
          <img
            src="{{ item.inactiveLink }}"
            alt="link-img"
            [ngClass]="!currentUrl?.includes(item?.url) ? 'd-block' : 'd-none'"
          />
          <span class="name ml-3">{{ item.name }}</span>
        </span>
        <span *ngIf="item?.hasTag && item.name == 'Notification'" class="tag">{{
          notificationCount
        }}</span>
        <span *ngIf="item?.hasTag && item.name == 'Inbox'" class="tag">{{
          inboxCount
        }}</span>
        <img
          *ngIf="item?.dropdown"
          class="pointer"
          src="/assets/icons/white-down.svg"
          alt="arrow down svg"
        />
      </a>
      <div *ngIf="children && children === item" class="submenu">
        <div class="" *ngFor="let child of item?.children">
          <a
            routerLinkActive="active-child"
            routerLink="{{ child?.url }}"
            class="d-flex align-items-center justify-content-between child-ancor"
            [ngClass]="currentUrl?.includes(child?.url) ? 'active-child' : ''"
          >
            <span class="d-flex align-items-center">
              <span class="name child-name ml-3">{{ child?.name }}</span>
            </span>
          </a>
        </div>
      </div>
    </li>
  </ul>

  <div class="bottom-section">
    <ul class="list-items">
      <li class="sidenav-menu-item">
        <a class="d-flex align-items-center" (click)="logout()">
          <img src="assets/images/sidebar/logout.svg" alt="logout-img" />
          <span class="name ml-3">Log out</span>
        </a>
      </li>
    </ul>

    <div class="pl-3 pt-3 user-bottom">
      <app-user-details></app-user-details>
    </div>
  </div>
</div>

<button
  class="d-none"
  id="toggleLogoutModal"
  data-toggle="modal"
  data-target="#logoutModal"
></button>

<div
  class="modal fade"
  id="logoutModal"
  tabindex="-1"
  aria-labelledby="logoutModal"
  aria-hidden="true"
>
  <div class="modal-dialog modal-dialog-centered modal-note">
    <div class="modal-content">
      <div class="modal-body p-4">
        <div class="my-4 d-flex justify-content-center">
          <svg
            width="66"
            height="66"
            viewBox="0 0 66 66"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect x="5" y="5" width="56" height="56" rx="28" fill="#D1FADF" />
            <path
              d="M44.6654 31.9267V33C44.6639 35.5158 43.8493 37.9638 42.3429 39.9788C40.8366 41.9938 38.7192 43.4679 36.3066 44.1812C33.894 44.8945 31.3155 44.8089 28.9556 43.937C26.5957 43.0652 24.5808 41.4538 23.2115 39.3432C21.8422 37.2327 21.1918 34.7361 21.3573 32.2257C21.5229 29.7153 22.4954 27.3257 24.13 25.4133C25.7646 23.5008 27.9736 22.168 30.4276 21.6135C32.8816 21.0591 35.449 21.3127 37.747 22.3367M44.6654 23.6667L32.9987 35.345L29.4987 31.845"
              stroke="#039855"
              stroke-width="2.33333"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <rect
              x="5"
              y="5"
              width="56"
              height="56"
              rx="28"
              stroke="#ECFDF3"
              stroke-width="9.33333"
            />
          </svg>
        </div>
        <div>
          <p class="text-center f-18 my-4">Are you sure you want to logout ?</p>

          <div class="d-flex justify-content-center mt-3">
            <button
              data-dismiss="modal"
              aria-label="Close"
              id="closeModal"
              class="btn-outline px-5"
            >
              No, Cancel
            </button>
            &nbsp; &nbsp;
            <button
              type="submit"
              class="btn__custom--purple w-auto px-5"
              (click)="logout()"
            >
              Yes, Logout
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
