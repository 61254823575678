import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-drawer',
  templateUrl: './drawer.component.html',
  styleUrls: ['./drawer.component.scss']
})
export class DrawerComponent {
  @Input() headerTitle: string = '';
  @Output() closeButtonClicked = new EventEmitter();  

  closeDrawer() {
    this.closeButtonClicked.emit('close-drawer');
  }

}
