import { Injectable } from '@angular/core';
import * as signalR from '@microsoft/signalr';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class SignalRService {
  private hubConnection!: signalR.HubConnection;

  constructor() {}

  startConnection(token: any) {
    this.hubConnection = new signalR.HubConnectionBuilder()
      .withUrl(`${environment.serverUrl}Notification`, {
        accessTokenFactory: () => token,
      })
      .build();

    this.hubConnection.on('SendClaimProcessHistoryNotification', (data) => {});

    this.hubConnection
      .start()
      .then(() => console.log('Connection started'))
      .catch((err) => console.log('Error while starting connection: ' + err));
  }

  receiveClaimProcessHistoryNotification(): Observable<any> {
    return new Observable<any>((observer) => {
      this.hubConnection.on(
        'SendClaimProcessHistoryNotification',
        (data: any) => {
          observer.next(data);
        }
      );
    });
  }
}
