import { Injectable } from '@angular/core';
import {
  Router,
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot
} from '@angular/router';

import { CredentialsService } from '../../credentials/credentials.service';

@Injectable({
  providedIn: 'root'
})
export class BranchGuard implements CanActivate {
  constructor(
    private router: Router,
    private credentialsService: CredentialsService
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean {
    if (this.credentialsService.isAuthenticated() && 
    this.credentialsService?.credentials?.userRole?.toLowerCase() === 'branch') {
      return true;
    }
    this.credentialsService.setCredentials();
    this.router.navigate(['/auth'], { queryParams: { returnUrl: state.url } });
    return false;
  }
}
