<div class="custom-date-modal" [ngClass]="{ 'open-modal': isOpen }">
  <div class="custom-dates-container">
    <div class="custom-dates-element">
      <label for="startDate">Start Date</label>
      <input
        type="date"
        name="startDate"
        id="startDate"
        [(ngModel)]="startDate"
      />
    </div>
    <div class="custom-dates-element">
      <label for="endDate">End Date</label>
      <input type="date" name="endDate" id="endDate" [(ngModel)]="endDate" />
    </div>
    <div class="buttons">
      <button class="btn clearBtn" (click)="clearModal()">Clear</button>
      <button
        class="btn"
        (click)="submitCustom()"
        [disabled]="endDate.length < 1 || startDate.length < 1"
      >
        Apply
      </button>
    </div>
    <svg
      width="25"
      height="25"
      viewBox="0 0 16 16"
      fill="red"
      xmlns="http://www.w3.org/2000/svg"
      class="clear"
      (click)="closeModal.emit()"
      (keydown.press)="closeModal.emit()"
    >
      <path
        fill-rule="evenodd"
        d="M4.146 4.146a.5.5 0 01.708 0L8 7.293l3.146-3.147a.5.5 0 11.708.708L8.707 8l3.147 3.146a.5.5 0 11-.708.708L8 8.707l-3.146 3.147a.5.5 0 01-.708-.708L7.293 8 4.146 4.854a.5.5 0 010-.708z"
      ></path>
    </svg>
  </div>
</div>
