import { Component, OnInit } from '@angular/core';
import { PageEvent } from '@angular/material/paginator';
import { CredentialsService } from 'src/app/authentication/credentials/credentials.service';
import { NotificationsService } from 'src/services/classes/notifications/notifications.service';
import { MessageService } from 'src/services/data/messaging/message.service';

@Component({
  selector: 'app-user-notification',
  templateUrl: './user-notification.component.html',
  styleUrls: ['./user-notification.component.scss'],
})
export class UserNotificationComponent implements OnInit {
  allNotifications: any[] = [];
  pageEvent: PageEvent = {
    length: 0,
    pageIndex: 1,
    pageSize: 10,
  };
  selectedId: any;
  selectedItem: any = null;
  startSerialNumber: number = 1;
  keyword: string = '';
  filter: string = '';
  isSearch: boolean = false;
  userId: string | undefined;
  tobeReadNotifications: any[] = [];
  notificationCount: string = '';

  constructor(
    private messageService: MessageService,
    private notify: NotificationsService,
    private credentialService: CredentialsService
  ) {}

  ngOnInit() {
    this.userId = this.credentialService.credentials?.UserId;
    this.fetchAllNotifications();
    this.updateNotficationCount();
  }

  fetchAllNotifications = () => {
    this.messageService
      .fetchAllNotifications(
        this.pageEvent.pageIndex,
        this.pageEvent.pageSize,
        this.userId,
        this.keyword,
        this.filter
      )
      .subscribe((res) => {
        if (!res.hasErrors) {
          this.allNotifications = res.payload;
          this.pageEvent.length = res.totalCount;
          this.updateStartSerialNumber();
        } else {
          this.notify.publishMessages(res.description, 'danger', 1);
        }
      });
  };

  notificationFilter(event: any) {
    this.filter = event.target.value;
    this.fetchAllNotifications();
  }

  getRelativeDate(date: any): string {
    const formattedDate = new Date(date);
    const now = new Date();
    const diffInMilliseconds = now.getTime() - formattedDate.getTime();
    const diffInDays = Math.floor(diffInMilliseconds / (1000 * 60 * 60 * 24));

    if (diffInDays === 0) {
      return 'today';
    } else if (diffInDays === 1) {
      return '1 day ago';
    } else {
      return `${diffInDays} days ago`;
    }
  }

  view(item: any) {
    this.selectedId = item.id;
    this.selectedItem = item;
  }

  search = () => {
    this.isSearch = true;
    this.resetPagination();
    this.fetchAllNotifications();
  };

  clear = () => {
    this.keyword = '';
    this.filter = '';
    this.isSearch = false;

    this.resetPagination();
    this.fetchAllNotifications();
  };

  resetPagination() {
    this.pageEvent = {
      length: 0,
      pageIndex: 1,
      pageSize: 10,
    };
    this.startSerialNumber = 1;
  }

  updateStartSerialNumber() {
    this.startSerialNumber =
      this.pageEvent.pageIndex * this.pageEvent.pageSize -
      this.pageEvent.pageSize +
      1;
  }

  onPageChange(event: PageEvent) {
    this.pageEvent = { ...this.pageEvent, ...event };
    let { pageIndex } = this.pageEvent;
    this.pageEvent.pageIndex = pageIndex + 1;
    this.fetchAllNotifications();
  }

  getNotification() {
    this.messageService.fetchNotification(this.selectedId).subscribe((res) => {
      this.selectedItem = res.payload;
    });
  }

  readNotifcations(event: any) {
    const payload = {
      userId: this.userId,
      ids: this.tobeReadNotifications,
      setNotificationStatus: event.target.checked && 2,
    };

    this.messageService.readNofication(payload).subscribe((res) => {
      if (res.hasErrors === false) {
        this.notify.publishMessages(res.payload, 'success', 0);
        this.updateNotficationCount();
        this.fetchAllNotifications();
        this.tobeReadNotifications = [];
      }
    });
  }

  getAllIds() {
    return this.allNotifications.map((notification) => notification.id);
  }

  handleCheckboxClick(event: MouseEvent): void {
    event.stopPropagation();
  }

  handleKeyUp(event: KeyboardEvent) {
    event.stopPropagation();
  }

  onMark(event: any, id: any) {
    if (event.target.checked) {
      this.tobeReadNotifications.push(id);
    } else {
      let index = this.tobeReadNotifications.indexOf(id);
      this.tobeReadNotifications.splice(index, 1);
    }
  }

  onMarkAll(event: any) {
    const payload = {
      userId: this.userId,
      ids: [],
      setNotificationStatus: event.target.checked ? 2 : 1,
      isMarkAllRead: true,
    };

    this.messageService.readNofication(payload).subscribe((res) => {
      if (res.hasErrors === false) {
        this.notify.publishMessages(
          event.target.checked
            ? 'All Notification  marked as read'
            : 'All Notification   marked as unread',
          'success',
          0
        );
        this.fetchAllNotifications();
        this.updateNotficationCount();
        this.tobeReadNotifications = [];
      }
    });
  }

  updateNotficationCount() {
    this.messageService.unreadNoficationCount(this.userId).subscribe((res) => {
      this.notify.updateVariable(res.payload.unRead);
      this.notificationCount = res.payload.unRead;
    });
  }

  markAsRead(event: any) {
    const payload = {
      userId: this.userId,
      ids: [this.selectedItem.id],
      setNotificationStatus: event.target.checked ? 2 : 1,
    };

    this.messageService.readNofication(payload).subscribe((res) => {
      if (res.hasErrors === false) {
        this.notify.publishMessages(
          event.target.checked
            ? 'Notification marked as read'
            : 'Notification marked as unread',
          'success',
          0
        );
        this.fetchAllNotifications();
        this.updateNotficationCount();
        this.tobeReadNotifications = [];
      }
    });
  }
}
