import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class BaseService<M> {
  public headers = {};
  constructor(public httpClient: HttpClient) {}

  sendGet(url: any): Observable<M> {
    return this.httpClient.get(url).pipe(
      map((body: any) => body),
      catchError(this.handleError)
    );
  }

  sendPost(url: any, payload: any): Observable<M> {
    return this.httpClient.post(url, payload).pipe(
      map((body: any) => body),
      catchError(this.handleError)
    );
  }

  sendDelete(url: any): Observable<M> {
    return this.httpClient.delete(url).pipe(
      map((body: any) => body),
      catchError(this.handleError)
    );
  }

  sendPut(url: any, payload: any): Observable<M> {
    return this.httpClient.put(url, payload).pipe(
      map((body: any) => body),
      catchError(this.handleError)
    );
  }

  sendPatch(url: any, payload: any): Observable<M> {
    return this.httpClient.patch(url, payload).pipe(
      map((body: any) => body),
      catchError(this.handleError)
    );
  }

  baseUrl(url: string) {
    return environment.serverUrl + url;
  }

  private handleError(error: HttpErrorResponse) {
    let errorData = {
      name: error.name,
      status: error.status,
      message: error.message,
    };

    if (
      !(error.error instanceof ErrorEvent) &&
      [401, 403, 504, 400].includes(error.status)
    ) {
      errorData = {
        name: error.error || 'Unknown Error',
        status: error.status,
        message: error.message,
      };
    }

    return throwError(() => new Error(JSON.stringify(errorData)));
  }
}
