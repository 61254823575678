import { NavigationEnd, Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { filter } from 'rxjs';
import { CredentialsService } from 'src/app/authentication/credentials/credentials.service';
import { MessageService } from 'src/services/data/messaging/message.service';
import { NotificationsService } from 'src/services/classes/notifications/notifications.service';
import { PageEvent } from '@angular/material/paginator';
import { AuthService } from 'src/services/data/auth/auth.service';
@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
})
export class SidebarComponent implements OnInit {
  adminMenu = [
    {
      name: 'Dashboard',
      url: '/admin/dashboard',
      activeLink: 'assets/images/sidebar/dashboard-active.svg',
      inactiveLink: 'assets/images/sidebar/dashboard-inactive.svg',
      hasTag: false,
      dropdown: false,
    },
    {
      name: 'Audit Trail',
      url: '/admin/audit-trail',
      activeLink: 'assets/images/sidebar/audit-active.svg',
      inactiveLink: 'assets/images/sidebar/audit-inactive.svg',
      hasTag: false,
      dropdown: false,
    },
    {
      name: 'Generate Report',
      url: '/admin/report',
      activeLink: 'assets/images/sidebar/reporting-active.svg',
      inactiveLink: 'assets/images/sidebar/reporting-inactive.svg',
      hasTag: false,
    },
    {
      name: 'User Management',
      url: '/admin/user-management',
      activeLink: 'assets/images/sidebar/user-active.svg',
      inactiveLink: 'assets/images/sidebar/user-inactive.svg',
      hasTag: false,
      dropdown: false,
    },
    {
      name: 'Notification',
      url: '/admin/notification',
      activeLink: 'assets/images/sidebar/notification-active.svg',
      inactiveLink: 'assets/images/sidebar/notification-inactive.svg',
      hasTag: true,
      dropdown: false,
    },
  ];

  branchMenu = [
    {
      name: 'Dashboard',
      url: '/branch/dashboard',
      activeLink: 'assets/images/sidebar/dashboard-active.svg',
      inactiveLink: 'assets/images/sidebar/dashboard-inactive.svg',
      hasTag: false,
      dropdown: false,
    },
    {
      name: 'Claims',
      url: '/branch/claims',
      activeLink: 'assets/images/sidebar/claims-active.svg',
      inactiveLink: 'assets/images/sidebar/claims-inactive.svg',
      hasTag: false,
      dropdown: false,
    },
    {
      name: 'Reporting',
      url: '/branch/report',
      activeLink: 'assets/images/sidebar/reporting-active.svg',
      inactiveLink: 'assets/images/sidebar/reporting-inactive.svg',
      hasTag: false,
      dropdown: false,
    },
    {
      name: 'Notification',
      url: '/branch/notification',
      activeLink: 'assets/images/sidebar/notification-active.svg',
      inactiveLink: 'assets/images/sidebar/notification-inactive.svg',
      hasTag: true,
      dropdown: false,
    },
  ];

  controlMenu = [
    {
      name: 'Dashboard',
      url: '/control/dashboard',
      activeLink: 'assets/images/sidebar/dashboard-active.svg',
      inactiveLink: 'assets/images/sidebar/dashboard-inactive.svg',
      hasTag: false,
      dropdown: false,
    },
    {
      name: 'Claims',
      url: '/control/claims',
      activeLink: 'assets/images/sidebar/claims-active.svg',
      inactiveLink: 'assets/images/sidebar/claims-inactive.svg',
      hasTag: false,
      dropdown: true,
      children: [
        { name: 'Branch', url: '/control/claims/branch' },
        { name: 'Merchant', url: '/control/claims/merchant' },
        { name: 'Agent', url: '/control/claims/agent' },
      ],
    },
    {
      name: 'Reporting',
      url: '/control/report',
      activeLink: 'assets/images/sidebar/reporting-active.svg',
      inactiveLink: 'assets/images/sidebar/reporting-inactive.svg',
      hasTag: false,
      dropdown: false,
    },
    {
      name: 'Notification',
      url: '/control/notification',
      activeLink: 'assets/images/sidebar/notification-active.svg',
      inactiveLink: 'assets/images/sidebar/notification-inactive.svg',
      hasTag: true,
      dropdown: false,
    },
  ];

  merchantMenu = [
    {
      name: 'Dashboard',
      url: '/merchant/dashboard',
      activeLink: 'assets/images/sidebar/dashboard-active.svg',
      inactiveLink: 'assets/images/sidebar/dashboard-inactive.svg',
      hasTag: false,
      dropdown: false,
    },
    {
      name: 'Claims',
      url: '/merchant/claims',
      activeLink: 'assets/images/sidebar/claims-active.svg',
      inactiveLink: 'assets/images/sidebar/claims-inactive.svg',
      hasTag: false,
      dropdown: false,
    },
    {
      name: 'Reporting',
      url: '/merchant/report',
      activeLink: 'assets/images/sidebar/reporting-active.svg',
      inactiveLink: 'assets/images/sidebar/reporting-inactive.svg',
      hasTag: false,
      dropdown: false,
    },
    {
      name: 'Notification',
      url: '/merchant/notification',
      activeLink: 'assets/images/sidebar/notification-active.svg',
      inactiveLink: 'assets/images/sidebar/notification-inactive.svg',
      hasTag: true,
      dropdown: false,
    },
  ];

  operationsMenu = [
    {
      name: 'Dashboard',
      url: '/operations/dashboard',
      activeLink: 'assets/images/sidebar/dashboard-active.svg',
      inactiveLink: 'assets/images/sidebar/dashboard-inactive.svg',
      hasTag: false,
      dropdown: false,
    },
    {
      name: 'Claims',
      url: '/operations/claims',
      activeLink: 'assets/images/sidebar/claims-active.svg',
      inactiveLink: 'assets/images/sidebar/claims-inactive.svg',
      hasTag: false,
      dropdown: true,
      children: [
        { name: 'Branch', url: '/operations/claims/branch' },
        { name: 'Merchant', url: '/operations/claims/merchant' },
        { name: 'Agent', url: '/operations/claims/agent' },
      ],
    },
    {
      name: 'Reporting',
      url: '/operations/report',
      activeLink: 'assets/images/sidebar/reporting-active.svg',
      inactiveLink: 'assets/images/sidebar/reporting-inactive.svg',
      hasTag: false,
      dropdown: false,
    },
    {
      name: 'Notification',
      url: '/operations/notification',
      activeLink: 'assets/images/sidebar/notification-active.svg',
      inactiveLink: 'assets/images/sidebar/notification-inactive.svg',
      hasTag: true,
      dropdown: false,
    },
  ];
  currentUrl: any;
  sideBarMenuItems: any[] = [];
  children: any = false;
  settingsMenu: boolean = false;
  currentMenu: any;
  notificationCount: any = 0;
  inboxCount: any = 0;
  userId: string | undefined;
  messageContainer = '';
  pageEvent: PageEvent = {
    length: 0,
    pageIndex: 1,
    pageSize: 10,
  };
  keyword: string = '';

  constructor(
    private router: Router,
    private credentialsService: CredentialsService,
    private messagingService: MessageService,
    private notify: NotificationsService,
    private authService: AuthService
  ) {
    router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe((event: any) => {
        this.currentUrl = event.url;
      });
  }

  ngOnInit() {
    this.userId = this.credentialsService.credentials?.UserId;

    this.notify.variable$.subscribe((res) => {
      this.notificationCount = res;
    });

    this.setMenuItem();
    this.messagingService
      .unreadNoficationCount(this.userId)
      .subscribe((res) => {
        this.notify.updateVariable(res.payload.unRead);
      });
  }

  setMenuItem() {
    const roleToMenuMappedTogether = {
      merchant: this.merchantMenu,
      agent: this.merchantMenu,
      superadmin: this.adminMenu,
      cardops: this.operationsMenu,
      branch: this.branchMenu,
      controlunit: this.controlMenu,
    };

    const userRole = this.credentials()
      ?.userRole?.toLowerCase()
      .replace(/\s/g, '');

    if (userRole && roleToMenuMappedTogether.hasOwnProperty(userRole)) {
      this.currentMenu = (roleToMenuMappedTogether as any)[userRole];
      this.sideBarMenuItems = this.currentMenu;
    }
  }

  credentials() {
    return this.credentialsService.credentials;
  }

  showChildren(item: any): void {
    this.children = item;
  }

  showSettings = () => {
    this.settingsMenu = !this.settingsMenu;
  };

  callLogout() {
    this.authService.logout({}).subscribe((res) => {
      if (!res.hasErrors) {
        this.handleLogoutFeedBack(res?.payload);
      }
    });
  }

  handleLogoutFeedBack(payload: string) {
    this.credentialsService.setCredentials();
    this.router.navigate(['/auth']);
    this.notify.publishMessages(payload, 'success', 1);
  }

  logout() {
    this.callLogout();
  }

  searchMenu = (e: any) => {
    if (e.target.value !== '') {
      let name = e.target.value.toLowerCase();
      let items = this.filterSearchMenu(this.sideBarMenuItems, name);
      this.sideBarMenuItems = items;
    } else {
      this.sideBarMenuItems = this.currentMenu;
    }
  };

  filterSearchMenu = (sideBarMenuItems: any[], name: any) => {
    return sideBarMenuItems.filter((menuItem) =>
      menuItem.name.toLowerCase().includes(name)
    );
  };
}
