enum LogLevel {
  Off = 0,
  Error,
  Warning,
  Info,
  Debug,
}

export const environment = {
  production: false,
  productLogoLarge: 'assets/images/app-logo.png',
  serverUrl: 'https://devapi.fcmb.com/fcmb-nou-api/api/',
  client_id: "250",
  subscription_key_value: "9a6776ebf5f341b4af1ccccbef282ef0",
  password: "Tt9=dEB\$4FdruOjlg1j1^sNR",
  loggerLevel: LogLevel.Debug,

};