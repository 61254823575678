import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BaseService } from 'src/services/data/base/base.service';

const routes = {
  guestLogin: 'authentication/guestLogin',
  fcmbLogin: 'authentication/FCMBLogin',
  resend2FA: 'authentication/Resend2FAToken',
  logout: 'authentication/Logout',
};

@Injectable({
  providedIn: 'root',
})
export class AuthService extends BaseService<any> {
  guestLogin(payload: any): Observable<any> {
    return this.sendPost(this.baseUrl(routes.guestLogin), payload);
  }

  fcmbLogin(payload: any): Observable<any> {
    return this.sendPost(this.baseUrl(routes.fcmbLogin), payload);
  }

  resend2FAGuest(payload: any): Observable<any> {
    return this.sendPost(this.baseUrl(routes.resend2FA), payload);
  }

  logout(payload: any): Observable<any> {
    return this.sendPost(this.baseUrl(routes.logout), payload);
  }
}
