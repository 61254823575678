import { Injectable } from '@angular/core';
import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpResponse,
  HttpErrorResponse,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import * as CryptoJS from 'crypto-js';
import { LoaderService } from '../../classes/loader/loader.service';
import { Router } from '@angular/router';
import { NotificationsService } from '../../classes/notifications/notifications.service';
import { CredentialsService } from 'src/app/authentication/credentials/credentials.service';
import { AuthService } from 'src/services/data/auth/auth.service';
import { environment } from 'src/environments/environment';

@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {
  errorMessage: string = '';
  isLoggingOut: boolean = false;
  constructor(
    private loaderService: LoaderService,
    private router: Router,
    private credentialsService: CredentialsService,
    private notification: NotificationsService,
    private authService: AuthService
  ) { }

  logout() {
    this.isLoggingOut = true;
    this.authService.logout({}).subscribe((res) => {
      if (!res.hasErrors) {
        this.credentialsService.setCredentials();
        this.router.navigate(['/auth']);
      }
      this.isLoggingOut = false;
    });
  }

  createSHA512Hash(clientID: string, secretKey: string): string {
    const timeStamp = new Date().toUTCString();
    // Concatenate the data
    const data = `${timeStamp}${clientID}${secretKey}`;

    // Create a SHA-512 hash
    const hash = CryptoJS.SHA512(data);

    // Convert the hash to a hexadecimal format
    const hashedData = hash.toString(CryptoJS.enc.Hex);

    return hashedData;
  }

  addHeaders(req: HttpRequest<any>): HttpRequest<any> {
    const timeStamp = new Date().toUTCString();
    const password = environment.password;
    const xToken = this.createSHA512Hash(environment.client_id, password);
    const sub = environment.subscription_key_value;
    const client_id = environment.client_id;

    return req.clone({
      setHeaders: {
        Client_ID: client_id,
        "Ocp-Apim-Subscription-Key": sub,
        "UTCTimestamp": timeStamp,
        "x-token": xToken
      },
    });

  }

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    this.loaderService.show();

    return next.handle(this.addHeaders(request)).pipe(
      tap((evt) => {
        if (evt instanceof HttpResponse) {
          this.loaderService.hide();
          if (evt.body.error) {
            this.errorMessage = evt.body.message;
            this.notification.publishMessages(this.errorMessage, 'danger', 1);
          }
        }
      }),

      catchError((error: HttpErrorResponse) => {
        this.loaderService.hide();
        console.log(error);
        if (error.error instanceof ErrorEvent) {
          this.errorMessage = `Error: ${error.error.message}`;
        } else {
          switch (error.status) {
            case 503: {
              this.errorMessage = 'Internal Server Error!';
              this.notification.publishMessages(this.errorMessage, 'danger', 1);
              break;
            }
            case 500: {
              this.errorMessage = 'Internal Server Error';
              this.notification.publishMessages(this.errorMessage, 'danger', 1);
              break;
            }
            case 400: {
              const errorMessage = error.error.message;
              this.notification.publishMessages(errorMessage, 'danger', 1);
              break;
            }
            case 404: {
              this.errorMessage = 'An Error Occurred, try again';
              this.notification.publishMessages(this.errorMessage, 'danger', 1);
              break;
            }
            case 403: {
              this.notification.publishMessages('Access Denied', 'danger', 1);

              if (!this.isLoggingOut) {
                this.logout();
              }
              break;
            }
            case 401: {
              this.notification.publishMessages(
                'Session Timed Out',
                'danger',
                1
              );
              this.router.navigate(['/auth']);
              break;
            }
            case 405: {
              this.errorMessage = 'Internal Server Error!!';
              this.notification.publishMessages(this.errorMessage, 'danger', 1);
              break;
            }
            case 0: {
              this.errorMessage =
                'Connection Error. Check Your Internet Connection';
              this.notification.publishMessages(this.errorMessage, 'danger', 1);
              break;
            }
          }
        }

        return throwError(() => error.error);
      })
    );
  }
}