import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-custom-date-modal',
  templateUrl: './custom-date-modal.component.html',
  styleUrls: ['./custom-date-modal.component.scss'],
})
export class CustomDateModalComponent {
  constructor() {}

  @Input() isOpen: boolean = true;
  @Output() closeModal: EventEmitter<void> = new EventEmitter<void>();
  @Output() submitDates: EventEmitter<{ startDate: string; endDate: string }> =
    new EventEmitter<{ startDate: string; endDate: string }>();

  startDate: string = '';
  endDate: string = '';

  clearModal() {
    this.startDate = '';
    this.endDate = '';
  }

  submitCustom() {
    this.submitDates.emit({ startDate: this.startDate, endDate: this.endDate });
    this.closeModal.emit();
    this.clearModal();
  }
}
