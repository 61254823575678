<aside class="drawer drawer-default">
  <div class="drawer-header pb-0">
    <div class="drawer-header__title">
      <h4 class="mb-0 p-0 font-weight-medium">{{ headerTitle }}</h4>
      <button class="btn mb-0 p-0" (click)="closeDrawer()">
        <img src="/assets/icons/drawer-close-btn.svg" alt="close" />
      </button>
    </div>
    <div class="mt-1 mb-4" id="progress"></div>
  </div>
  <div class="drawer-body">
    <ng-content select="[drawerBody]"></ng-content>
  </div>
</aside>
