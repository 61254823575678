import { Component } from '@angular/core';
import { CredentialsService } from 'src/app/authentication/credentials/credentials.service';

@Component({
  selector: 'app-user-details',
  templateUrl: './user-details.component.html',
  styleUrls: ['./user-details.component.scss'],
})
export class UserDetailsComponent {
  constructor(private credentialsService: CredentialsService) {}

  credentials() {
    return this.credentialsService.credentials;
  }
}
